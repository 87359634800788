import React from 'react'
import { PageWrapper } from '../../Styles/style'
import HeroSection from '../../Sections/S1_Hero'
import AboutSection from '../../Sections/S2_About'
import Tokenomics from '../../Sections/S3_Tokenomics'
import DisclaimerSection from '../../Sections/S4_Disclaimer'
import Marquee from 'react-fast-marquee'
import Paragraph from '../../Components/Paragraph'
import { Box } from '@mui/material'
import LockerMenu from '../../Components/LockerMenu'
import HowToBuy from '../../Sections/Howtobuy'

const MainPage = (props) => {
  return (
    <PageWrapper>
      <HeroSection/>
      <Box m={{xs:"100px 0 0",sm:"0px 0 0"}}>

      <Marquee style={{background:"#1717FF",width:"calc(100% + 40px)",left:"-20px",rotate:"6deg"}}>
        <Paragraph color="#00FF00" textTransform="uppercase"  m={{xs:"10px 20px",sm:"20px 30px"}} fontSize={{xs:"20px",sm:"40px",md:"50px",lg:"60px"}} fontFamily="dd">
            Matt fruie
        </Paragraph>
        <Paragraph color="#00FF00" textTransform="uppercase" m={{xs:"10px 20px",sm:"20px 30px"}} fontSize={{xs:"20px",sm:"40px",md:"50px",lg:"60px"}} fontFamily="dd">
            Matt fruie
        </Paragraph>
        <Paragraph color="#00FF00" textTransform="uppercase" m={{xs:"10px 20px",sm:"20px 30px"}} fontSize={{xs:"20px",sm:"40px",md:"50px",lg:"60px"}} fontFamily="dd">
            Matt fruie
        </Paragraph>
        <Paragraph color="#00FF00" textTransform="uppercase" m={{xs:"10px 20px",sm:"20px 30px"}} fontSize={{xs:"20px",sm:"40px",md:"50px",lg:"60px"}} fontFamily="dd">
            Matt fruie
        </Paragraph>
        <Paragraph color="#00FF00" textTransform="uppercase" m={{xs:"10px 20px",sm:"20px 30px"}} fontSize={{xs:"20px",sm:"40px",md:"50px",lg:"60px"}} fontFamily="dd">
            Matt fruie
        </Paragraph>
        <Paragraph color="#00FF00" textTransform="uppercase" m={{xs:"10px 20px",sm:"20px 30px"}} fontSize={{xs:"20px",sm:"40px",md:"50px",lg:"60px"}} fontFamily="dd">
            Matt fruie
        </Paragraph>
        <Paragraph color="#00FF00" textTransform="uppercase" m={{xs:"10px 20px",sm:"20px 30px"}} fontSize={{xs:"20px",sm:"40px",md:"50px",lg:"60px"}} fontFamily="dd">
            Matt fruie
        </Paragraph>
      </Marquee>
      <Marquee style={{background:"#00FF00",width:"calc(100% + 40px)",left:"-20px",rotate:"-6deg",top:"-120px"}}>
        <Paragraph color="#010272" textTransform="uppercase" m={{xs:"10px 20px",sm:"20px 30px"}} fontSize={{xs:"20px",sm:"40px",md:"50px",lg:"60px"}} fontFamily="dd">
           Nogwai
        </Paragraph>
        <Paragraph color="#010272" textTransform="uppercase" m={{xs:"10px 20px",sm:"20px 30px"}} fontSize={{xs:"20px",sm:"40px",md:"50px",lg:"60px"}} fontFamily="dd">
           Nogwai
        </Paragraph>
        <Paragraph color="#010272" textTransform="uppercase" m={{xs:"10px 20px",sm:"20px 30px"}} fontSize={{xs:"20px",sm:"40px",md:"50px",lg:"60px"}} fontFamily="dd">
           Nogwai
        </Paragraph>
        <Paragraph color="#010272" textTransform="uppercase" m={{xs:"10px 20px",sm:"20px 30px"}} fontSize={{xs:"20px",sm:"40px",md:"50px",lg:"60px"}} fontFamily="dd">
           Nogwai
        </Paragraph>
        <Paragraph color="#010272" textTransform="uppercase" m={{xs:"10px 20px",sm:"20px 30px"}} fontSize={{xs:"20px",sm:"40px",md:"50px",lg:"60px"}} fontFamily="dd">
           Nogwai
        </Paragraph>
        <Paragraph color="#010272" textTransform="uppercase" m={{xs:"10px 20px",sm:"20px 30px"}} fontSize={{xs:"20px",sm:"40px",md:"50px",lg:"60px"}} fontFamily="dd">
           Nogwai
        </Paragraph>
        <Paragraph color="#010272" textTransform="uppercase" m={{xs:"10px 20px",sm:"20px 30px"}} fontSize={{xs:"20px",sm:"40px",md:"50px",lg:"60px"}} fontFamily="dd">
           Nogwai
        </Paragraph>
      </Marquee>
      </Box>
      <AboutSection/>
      <Tokenomics/>
      <HowToBuy/>
      <DisclaimerSection/>
    </PageWrapper>
  )
}

export default MainPage