import { Container } from '@mui/material';
import React from 'react'
import DesktopMenu from './component/DesktopMenu';
import MobileMenu from './component/MobileMenu';

const LockerMenu = (props) => {
    const menuList = [ // You can pass 4 perameters for menu title, link, target and customClass
        {
        title: "Home",
        link: "/",
        },
        {
        title: "About",
        link: "#About",
        },
        {
        title: "Tokenomics",
        link: "#Tokenomics",
        },
        {
        title: "How to buy",
        link: "#Howtobuy",
        },
        {
        title: "Buy Now",
        link: "",
        customClass: "",
        target: "_blank"
        },
    ];
  return (
    <div>
        <DesktopMenu menuList={menuList} />
        <MobileMenu menuList={menuList} />
    </div>
  )
}

export default LockerMenu