import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import logo from "../../../../assets/logotext.png";
import logosingle from "../../../../assets/logosingle.png";
import { DesktopMainMenu, MaterialUISwitch, MenuLink } from "../styles";
import { toggleTheme } from '../../../../Redux/switchTheme'
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import Paragraph from "../../../Sections/Paragraph";

const DesktopMenu = (props) => {
  const currentTheme = useSelector( (state) => state.LDTheme.value )
  const dispatch = useDispatch();
 
  return (
    <DesktopMainMenu maxWidth="xl">
      <MenuLink href="/" p="0px">
       <div style={{position:"relative", display:"flex"}}>

        <img style={{position:"absolute" , top:"-30px" ,left:"0"}} src={logosingle} alt="Logo" />
        <img style={{marginLeft:"141px"}}src={logo} width="175" height='65' alt="Logo" />
        {/* <Paragraph>asdasdd</Paragraph> */}
       </div>
      </MenuLink>
      <div>
        {props.menuList.map((value, i) => <MenuLink key={i} href={value.link} target={value.target} className={value.customClass}>{value.title}</MenuLink> )}
        {/* <FormControlLabel
          control={
            <MaterialUISwitch 
              sx={{ m: 1 }} 
              checked={currentTheme}
            />
          }
          onClick={() => dispatch(toggleTheme())}
        /> */}
      </div>
    </DesktopMainMenu>
  );
};

export default DesktopMenu;
