import emotionStyled from '@emotion/styled'
import React, { useState } from 'react'
import heroBg from "../../../assets/heroBg.jpg"
import { Box, Container, Grid, Typography } from '@mui/material'
import { Image } from '../../Styles/style'
import CustomButton from '../../Components/CustomButton'
import { contractAddress } from '../../../links'
import LockerMenu from '../../Components/LockerMenu'
import Paragraph from '../../Components/Paragraph'
// import Paragraph from '../Paragraph'


const SectionWraper = emotionStyled(Box)`
    background-image:url(${heroBg});
    background-size:cover;
    background-position: center;
    background-repeat:no-repeat;
    min-height:100vh;
    width:100%;
    display:flex;
    justify-content:center;
    position:relative;
    overflow:hidden;
    // border-bottom:50px solid transparent;
    // border-image: linear-gradient(to top,#FF4F01, transparent) 30;
`
const HeroSection = () => {
    const [isCopied, setIsCopied] = useState(false)
    const handleCopy = async () => {
        setIsCopied(true)
        await navigator.clipboard.writeText(contractAddress)
        setTimeout(() => {
            setIsCopied(false)
        }, 3000);
    }

    return (
        <SectionWraper>
            <Container maxWidth="xl"  >

                <LockerMenu />
                <Grid container sx={{ display: "flex", minHeight: "100vh", justifyContent: "center", alignItems: { xs: "left", md: "left" } }}>
                    <Grid item xs={12} xl={6} alignContent='center'>
                        <Paragraph p="60px 0px 0" textAlign="left" color="#00FF00" lineHeight="70px" textTransform="uppercase" fontFamily="dd" fontSize={{ xs: "50px", sm: "70px" }}>
                            Nogwai<br />
                            BY<br />
                            Matt fruie
                        </Paragraph>
                        <Typography textAlign="left" p="20px 0px" >
                            <Typography  textAlign="left" whiteSpace="nowrap" color="#fff" fontSize={{ xs: "13px", sm: "22px" }} p="101px 6px" component={"span"}>nogwai by matt furie ke niche ka pura hi hta dete hain</Typography>
                        </Typography>
                        <CustomButton sx={{ minWidth: "320px" }} onClick={handleCopy}>
                            {
                                isCopied ? "Copied" : "Copy Contract Address"
                            }

                        </CustomButton>
                    </Grid>
                   < Grid item xs={12} xl={6} />
                </Grid>
            </Container>
        </SectionWraper>
    )
}

export default HeroSection