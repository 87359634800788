import emotionStyled from '@emotion/styled'
import React from 'react'
import aboutbg from "../../../assets/aboutbg.png"
import aboutimg from "../../../assets/aboutImage.png"
import { Box, Container, Grid } from '@mui/material'
import { Image } from '../../Styles/style'
import CustomButton from '../../Components/CustomButton'
import Paragraph from '../../Components/Paragraph'
import { tg, x } from '../../../links'
import styled from '@emotion/styled'
import twitter from "../../../assets/X.png"
import telegram from "../../../assets/TG.png"

const SectionWraper = emotionStyled(Box)`
    background-image:url(${aboutbg});
    background-size:cover;
    background-position: center;
    background-repeat:no-repeat;
    min-height:auto;
    width:100%;
    display:flex;
    padding:0px 0 30px;
    justify-content:center;
    align-items:center;
    position:relative;
    // border-bottom:50px solid transparent;
    // border-image: linear-gradient(to top,#FF4F01, transparent) 30;
`
const AboutImage = styled.img`
width: 100%;
height:auto;
max-width:600px;
`
const Icons = styled.img`
width: 100%;
height:auto;
max-width:50px;

transition: all 0.2s ease;
    box-shadow: -4px 6px 0px 2px black;
:active{
        
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        transform: translateY(2px);
    
    }
`
const AboutSection = () => {


    return (
        <SectionWraper id="About">
            <Container maxWidth="xl" >
                <Grid container sx={{ display: "flex", justifyContent: "left", alignItems: { xs: "start", md: "center" } }}>
                    <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "center", alignItems: { xs: "start", md: "center" } }}>
                        <AboutImage src={aboutimg} />
                    </Grid>
                    <Grid container item xs={12} md={6} sx={{ display: "flex", justifyContent: "left", alignItems: "left", padding: "0 0px 0px", }}>
                        <Paragraph className="textStroke" fontFamily="dd" textALign="left" fontSize={{ xs: "50px", sm: "90px" }}>
                            ABOUT
                        </Paragraph>
                        <Paragraph textAlign="left" fontFamily="Quicksand" mb={{ md: "20px" }} mt={{ xs: "0", md: "20px" }} fontWeight="800">
                            Nogwai, created by Matt Furie, is a captivating figure known for his whimsical yet enigmatic presence. With a spherical body adorned in intricate patterns and soulful eyes that reflect deep contemplation, Nogwai embodies a serene demeanor and mischievous spirit. Often found in serene natural settings like lush forests or tranquil ponds, he interacts with other creatures and explores his surroundings with gentle curiosity. Through Furie's artistry, Nogwai symbolizes harmony and introspection, inviting viewers to reflect on simplicity and the importance of inner connection.

                        </Paragraph>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Paragraph textAlign="left" fontFamily="Quicksand" mt={{ xs: "0", md: "0px" }} fontWeight="800">
                                Find us on:
                            </Paragraph>
                            &nbsp;
                            &nbsp;
                            <a href={x} target="blank">
                                <Icons src={twitter} />
                            </a>
                            &nbsp;
                            &nbsp;
                            <a href={tg} target="blank">
                                <Icons src={telegram} />
                            </a>
                        </div>


                    </Grid>
                </Grid>
            </Container>
        </SectionWraper >
    )
}

export default AboutSection