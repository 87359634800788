import styled from "@emotion/styled";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import zIndex from "@mui/material/styles/zIndex";
import Paragraph from "../Paragraph";
import howtobuy from '../../../assets/htbbg.png'
import emotionStyled from "@emotion/styled";


const SectionWrapper = emotionStyled(Box)`
    background-image: url(${howtobuy});
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    padding: 80px 0 150PX;
    
`
const CustomGrid = emotionStyled(Grid)`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => (props.fd ? props.fd : "row")};
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  gap: ${(props) => (props.g ? props.g : "0")};
  @media (max-width: 599px) {
    flex-direction: ${(props) => props.fd599};
  }
`;
// text-shadow: 0 2px 5px #ec1826;
//     -webkit-text-stroke: 2px black;
const HowToBuy = () => {
    return (
        <SectionWrapper >
            <Container maxWidth="xxl" id="Howtobuy">
                <Container maxWidth="xl" >
                    <Grid container sx={{ display: "flex", gap: { xs: "30px", lg: "50px" } }} >
                        <CustomGrid item xs={12}><Typography variant='h3' fontFamily="dd" textAlign='center' color="#1717FF" textTransform="uppercase" >How to Buy</Typography></CustomGrid>
                        <CustomGrid item container xs={12} sx={{ position: "relative" }} g="20px">
                            <Divider sx={{ display: { xs: "none", lg: "block" }, position: "absolute", top: "30px", left: "50%", transform: "translate(-50%,-0px)", background: "#000", height: "2px", width: "60%" }} />
                            <CustomGrid container item xs={12} md={5.8} lg={3.3} ai="start" fd="column" g="20px">
                                <CustomGrid
                                    item
                                    xs={12}
                                >
                                    <Paragraph
                                        // data-aos="zoom-in"
                                        variant="h6"
                                        fontSize={{ xs: "35px", lg: "35px" }}
                                        style={{ '-webkit-text-stroke': '2px #00FF00' }}
                                        sx={{ backgroundColor: "#1717FF", border: '3px solid #02028D', color: "#00FF00", fontFamily: 'dd', padding: "0px 25px", zIndex: "1" }}
                                    >
                                        01
                                    </Paragraph>
                                </CustomGrid>
                                <CustomGrid
                                    item
                                    xs={12}
                                >
                                    <Box sx={{ backgroundColor: "#1717FF", minHeight: "220px", padding: "30px 30px 35px", width: "100%", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "start", gap: "5px" }}>
                                        <Paragraph color="#fff" variant="h5" fontSize="25px" margin="auto"marginBottom={2}  fontFamily="dd">
                                        Fund your MetaMask wallet with Ethereum
                                        </Paragraph>
                                        <Paragraph variant="body2" minHeight='243px' color="#fff" fontFamily="Quicksand" fontWeight="700" fontSize={{ xs: "17px" }}>Send Ethereum (ETH) to your MetaMask wallet address. You can buy ETH on a cryptocurrency exchange like Coinbase, Binance, or Kraken, and then transfer it to your MetaMask wallet.</Paragraph>
                                    </Box>
                                </CustomGrid>
                            </CustomGrid>
                            <CustomGrid
                                container
                                item
                                xs={12}
                                md={5.8}
                                lg={3.3}
                                ai="start"
                                fd="column"
                                g="20px"
                            >
                                <CustomGrid
                                    item
                                    xs={12}
                                >
                                    <Paragraph
                                        // data-aos="zoom-in" 
                                        variant="h6"
                                        fontSize={{ xs: "35px", lg: "35px" }}
                                        style={{ '-webkit-text-stroke': '2px #00FF00' }}
                                        sx={{ backgroundColor: "#1717FF", border: '3px solid #02028D', color: "#00FF00", fontFamily: 'dd', padding: "0px 25px", zIndex: "1" }}
                                    >
                                        02
                                    </Paragraph>
                                </CustomGrid>
                                <CustomGrid
                                    item
                                    xs={12}
                                >
                                    <Box sx={{ backgroundColor: "#1717FF", minHeight: "220px", padding: "30px 30px 35px", width: "100%", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "start", gap: "5px" }}>
                                        <Paragraph color="#fff" variant="h5" fontSize="25px" margin="auto" marginBottom={2} fontFamily="dd">
                                        Connect to Uniswap and find the meme coin
                                        </Paragraph>
                                        <Paragraph variant="body2" minHeight='243px'  color="#fff" fontFamily="Quicksand" fontWeight="700" fontSize={{ xs: "17px" }}>Open MetaMask and click the "Swap" button. Select "Uniswap" as the exchange and put the contract address for nogwai. Make sure you select the correct coin, as some meme coins may have similar names.</Paragraph>
                                    </Box>
                                </CustomGrid>
                            </CustomGrid>
                            <CustomGrid
                                container
                                item
                                xs={12}
                                md={5.8}
                                lg={3.3}
                                ai="start"
                                fd="column"
                                g="20px"
                            >
                                <CustomGrid
                                    item
                                    xs={12}
                                >
                                    <Paragraph
                                        // data-aos="zoom-in"
                                        variant="h6"
                                        fontSize="35px"
                                        style={{ '-webkit-text-stroke': '2px #00FF00' }}
                                        sx={{ backgroundColor: "#1717FF", border: '3px solid #02028D', color: "#00FF00", fontFamily: 'dd', padding: "0px 25px", zIndex: "1" }}
                                    >
                                        03
                                    </Paragraph>
                                </CustomGrid>
                                <CustomGrid
                                    item
                                    xs={12}
                                >
                                    <Box sx={{ backgroundColor: "#1717FF", minHeight: "220px", padding: "30px 30px 35px", width: "100%", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "start", gap: "5px" }}>
                                        <Paragraph color="#fff" variant="h5" fontSize="25px" margin="auto" marginBottom={2} fontFamily="dd">
                                        Swap Ethereum for the meme coin
                                        </Paragraph>
                                        <Paragraph variant="body2" minHeight='243px' color="#fff" fontFamily="Quicksand" fontWeight="700" fontSize={{ xs: "17px" }}>Enter the amount of ETH you want to swap for the meme coin and confirm the transaction. MetaMask will generate a transaction request, which you should review carefully before confirming. Once confirmed, the meme coin will be added to your MetaMask wallet!</Paragraph>
                                    </Box>
                                </CustomGrid>
                            </CustomGrid>
                        </CustomGrid>
                    </Grid>
                </Container>
            </Container>
        </SectionWrapper>
    );
};

export default HowToBuy;