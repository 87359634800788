import emotionStyled from '@emotion/styled'
import React from 'react'
import disclaimerImage from "../../../assets/disclaimerImage.png"
import tokobg from "../../../assets/tokobg.png"
import X from "../../../assets/X.png"
import TG from "../../../assets/TG.png"
import { Box, Container, Grid, Link } from '@mui/material'
import { Image } from '../../Styles/style'
import Paragraph from '../../Components/Paragraph'
import BasicAccordion from '../../Components/Accordian'
import { tg, x } from '../../../links'
import styled from '@emotion/styled'


const SectionWraper = emotionStyled(Box)`
background-image:url(${tokobg});

// background:linear-gradient(to top,#A13100 80%,transparent 0%);
    // background-size:cover;
    // background-position: center;
    // background-repeat:no-repeat;
    // min-height:100vh;
    padding:150PX 0 100px;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    position:relative;
    // border-bottom:50px solid transparent;
    // border-image: linear-gradient(to top,#FF4F01, transparent) 30;
`

const Imagebg = styled.img`
width: 100%;
height: auto;
position: absolute;

top: -50px;
    left: 50%;
    right: 50%;
    max-width: 200PX;
    PLACE-SELF: CENTER;
max-width: ${props=>props.mw? props.mw : "200PX"};
display: ${props=>props.disp && props.disp };
@media(max-width: 599px){
    
display: ${props=>props.disp599 && props.disp599 };
}
`
const DisclaimerSection = () => {


  return (
    <>
    <SectionWraper>
        <Container maxWidth="lg" >
            <Grid container sx={{display:"flex",gap:"20px 0" ,justifyContent:"center",alignItems:{xs:"center",md:"start"}}}>
                <Grid item xs={12}>
                   <Imagebg src={disclaimerImage} alt=""/>
                </Grid>
                <Grid item xs={12}>
                   <Paragraph  fontFamily="dd" fontSize={{xs:"50px",sm:"90px"}}>
                        DISCLAIMER
                   </Paragraph>
                </Grid>
                <Grid item xs={12} md={8}>
                    <BasicAccordion def1="Legal Disclaimer" def2="NOGWAI is a cryptocurrency token. Its value and functionality are subject to market risks and regulatory changes. This is not investment advice. Please conduct thorough research before participating." ></BasicAccordion>
                    <BasicAccordion def1="Risk Disclosure" def2="Investing in NOGWAI involves substantial risk, including potential loss of principal. Cryptocurrency markets are volatile and can be affected by regulatory actions, technological changes, and market sentiment. Ensure you fully understand the risks before investing."></BasicAccordion>    
                </Grid>
                <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" gap="10px">
                    <Link href={x} target="blank">
                        <Image mw="60px" src={X} alt=""/>
                    </Link>
                    <Link href={tg} target="blank">
                        <Image mw="60px" src={TG} alt=""/>
                    </Link>
                </Grid>
            </Grid>
        </Container>
    </SectionWraper>
    <Box width="100%" padding="20px 10px" display="flex" alignItems="center" justifyContent="center">
        <Paragraph textAlign="center" fontFamily="Quicksand" fontWeight="800">
            COPYRIGHT © 2024. ALL RIGHTS RESERVED.
        </Paragraph>
    </Box>
    </>
  )
}

export default DisclaimerSection